import React, { useState, useEffect, useContext, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import queryStringParser from "query-string";
import {
  ContactInfoWorkflow,
  IContactInfo,
  TravelerSelectStep,
  ContactInfoStep,
  Header,
  MobilePopoverCard,
  BackButton,
  MobileFloatingButton,
  LoadingPopup,
  B2BSpinner,
  emailRegex,
  phoneRegex,
  Icon,
  IconName,
  InformationalModal as TreesModal,
  PolicyPill,
  ApprovalReasonWorkflow,
  ApprovalBanner,
  ApprovalReasonStep,
  LaunchBanner,
  TravelWalletSingleSelectionStep,
  TreesConfirmation,
  formatDateTimeWithTimeZone,
  VoidWindowNotice,
} from "halifax";
import "./styles.scss";
import { RouteComponentProps } from "react-router";
import {
  PriceDropProtectionEnum,
  PriceDropViewedProperties,
  PRICE_DROP_VIEWED,
  VIEWED_PRICE_DROP_DETAILS,
  ADD_CONTACT_INFO,
  FlightShopStep,
  FlightBookType,
  PRICE_FREEZE_ID_QUERY_PARAM,
  InvalidEnum,
  ConnectionResultEnum,
  TripCategory,
  MultiTicketTypeEnum,
  CorpFareDetails,
  FlightsSubmitForApprovalPayload,
  ProductFeedLoadingProperties,
  PRODUCT_FEED_LOADING,
  CorpSessionInfo,
} from "redmond";
import clsx from "clsx";
import { FlightShopSummaryPanel } from "../../../../shop/components/FlightShopSummaryPanel";
import { CorpMobileFlightBookWorkflowConnectorProps } from "./container";
import {
  PriceBreakdown,
  FlightBookPassengerSelection,
  PaymentCard,
  FlightBookMobileButton,
  PriceBreakdownDropdown,
  MobileSeatSelection,
  PriceDropProtection,
  AGENT_FEE,
} from "../..";
import {
  CONTACT_INFO_SAVE,
  CONTINUE,
  CONTACT_INFO_TITLE,
  CONTACT_INFO_SUBTITLE,
  REVIEW_MY_TRIP_TEXT,
  TITLE,
  SUBTITLE,
  PRICE_QUOTE_PASSENGER_VALIDATION_MESSAGE,
  SEAT_SELECTION_TITLE,
  PRICE_QUOTE_MESSAGE,
  CHECKOUT_PRICE_BREAKDOWN_HEADER,
  UPDATE_TRAVELERS,
  EDIT_TRAVELERS,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
} from "../../capone/MobileFlightBookWorkflow/textConstants";
import {
  PATH_BOOK,
  PATH_BOOK_CONFIRMATION,
  PATH_PRICE_FREEZE_CUSTOMIZE,
} from "../../../../../utils/urlPaths";
import { ClientContext } from "../../../../../App";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import submitForApproval from "../../../../../api/v0/book/book-flow/submitForApproval";
import { SeatSelectionStep } from "../../MobileSeatSelection";
import {
  AVAILABLE,
  SEAT_SELECTION,
  getExperimentVariant,
  useExperiments,
  TREES_MODAL_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  SEATS_UX_OPTIMIZATION,
  CREDIT_OFFER_STACKING_V1,
  PASSPORT_ENHANCEMENT,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  VOID_WINDOW_EXPERIMENT,
} from "../../../../../context/experiments";
import { fetchCustomerDetails } from "../../../../../api/v0/customer/fetchCustomerDetails";
import { usePrevious } from "../../../../../hooks/usePrevious";
import { MobileFlightBookWorkflowStep } from "../../../component";
import { TravelOfferSelection } from "../../TravelOfferSelection";
import { useSelector } from "react-redux";
import { getPriceDropRefundTypeSelector } from "../../../../ancillary/reducer";
import {
  IVirtualInterliningVariant,
  VirtualInterliningModal,
} from "../../../../shop/components/VirtualIinterliningModal";
import { MulticityFlightShopStep } from "../../../../shop/reducer";
import { MultipleAirlinesFareModal } from "../../../../shop/components/MultipleAirlinesFareModal";
import { useExperimentsById } from "@capone/experiments";

export interface ICorpMobileFlightBookWorkflowProps
  extends RouteComponentProps,
    CorpMobileFlightBookWorkflowConnectorProps {
  checkoutStep: MobileFlightBookWorkflowStep;
  setCheckoutStep: (step: any) => void;
  localTravelerIds: String[];
  setLocalTravelerIds: (ids: string[]) => void;
  localLapInfantIds: String[];
  setLocalLapInfantIds: (ids: string[]) => void;
}

export const CorpMobileFlightBookWorkflow = ({
  isWaitingValidation,
  selectedTrip,
  hasNoUserPassengers,
  history,
  finalizedItinerary,
  isBookingValid,
  scheduleQuote,
  setContactInfo,
  confirmationEmail,
  confirmationPhoneNumber,
  priceDropProtection,
  priceDropViewedProperties,
  populateFlightShopQueryParams,
  priceQuote,
  schedulePayment,
  currentSession,
  payments,
  ancillaries,
  seatMapAvailability,
  isWaitingPriceQuote,
  seatMapLoading,
  resetPaymentCardSelectedAccounts,
  hasError,
  errorTitles,
  setFlightShopProgress,
  missingPassport,
  checkoutStep,
  setCheckoutStep,
  flightBookType,
  offers,
  localLapInfantIds,
  setLocalLapInfantIds,
  localTravelerIds,
  setLocalTravelerIds,
  shopRequest,
  setSubmitForApproval,
  setSubmitForApprovalFailure,
  corporateTravel,
  tripDetails,
  disruptionDelayThreshold,
  tripCategory,
  fetchApplicableTravelWalletItems,
  credit,
  fetchInitialCrossSellHotelAvailability,
  airports,
  isVoidWindowEligible,
  isTravelWalletPaymentOnly,
  priceQuoteInProgress,
  creditToApply,
  fetchTravelWalletCreditHistory,
  hasSelectedDisruption,
  smsBeenSelected,
  appNotifBeenSelected,
  toggleAppNotifOptIn,
  toggleSMSOptIn,
  disruptionOptInEnabled,
  tcpaConsentPhoneNumber,
  setUserTcpaConsentPhoneNumber,
  isInternationalFlight,
  setMulticityFlightShopProgress,
  isCfarMulticityEnabled,
  fareDetails,
  selectedPaxIds,
}: ICorpMobileFlightBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal, isAutoApprovalEnabled, policies } =
    clientContext;

  const isInPolicyCheckout =
    // TODO(replat): add back after BE work is done
    // priceQuote?.corporateTravel.policyCompliance.isInPolicy ??
    corporateTravel?.policyCompliance.isInPolicy ?? true;

  const expState = useExperiments();
  const seatSelectionGroup = getExperimentVariant(
    expState.experiments,
    SEAT_SELECTION
  );
  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );

  const isSeatSelection = useMemo(
    () =>
      seatSelectionGroup === AVAILABLE &&
      (isInPolicyCheckout ||
        Boolean(isAutoApprovalEnabled) ||
        isApprovalsV2Enabled),
    [seatSelectionGroup, isInPolicyCheckout]
  );

  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const isVoidWindowExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, VOID_WINDOW_EXPERIMENT) ===
      AVAILABLE,
    [expState]
  );

  const isSeatsUXOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, SEATS_UX_OPTIMIZATION) ===
      AVAILABLE,
    [expState]
  );

  const isApprovalsV2Enabled =
    useExperimentsById("corp-approvals-v2")?.variant === "m2";

  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected = tripDetails.fareDetails[0]?.slices.some((slice) =>
    slice.fareDetails.segments.some((segment) => segment.isSelfTransferLayover)
  );

  // Follows patterns established elsewhere, but the flag is not (yet) needed in this context.
  const [openVIVariantModal, setOpenVIVariantModal] = useState<
    IVirtualInterliningVariant | "reminder" | false
  >(false);

  // Follows patterns established elsewhere, but the flag is not (yet) needed in this context.
  const [openMultipleAirlinesFares, setOpenMultipleAirlinesFares] =
    useState(false);

  const passportEnhancementExperiment = getExperimentVariant(
    expState.experiments,
    PASSPORT_ENHANCEMENT
  );
  const isPassportEnhancementExperiment = useMemo(() => {
    return passportEnhancementExperiment === AVAILABLE;
  }, [passportEnhancementExperiment]);

  const priceDropRefundType = useSelector(getPriceDropRefundTypeSelector);

  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: confirmationPhoneNumber || "",
    email: confirmationEmail || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    React.useState<boolean>(true);

  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [contactInfoStep, setContactInfoStep] = useState<ContactInfoStep>(
    ContactInfoStep.Main
  );
  const [seatSelectionStep, setSeatSelectionStep] = useState<SeatSelectionStep>(
    SeatSelectionStep.Main
  );
  const [travelWalletSelectStep, setTravelWalletSelectStep] =
    useState<TravelWalletSingleSelectionStep>(
      TravelWalletSingleSelectionStep.Main
    );
  const [approvalReasonStep, setApprovalReasonStep] =
    useState<ApprovalReasonStep>(ApprovalReasonStep.Main);
  const [openPaymentCard, setOpenPaymentCard] = useState<boolean>(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [updatedInfoOnReview, setUpdatedInfoOnReview] = useState(false);

  const isMulticity: boolean = tripCategory === TripCategory.MULTI_CITY;

  const disruptionDelayThresholdStringInHours =
    disruptionDelayThreshold === 180 ? "3" : "2";

  const [approvalRequestReason, setApprovalRequestReason] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (isCfarMulticityEnabled && isMulticity) {
      // Multi-city tracks flight shop progress a little differently. Book is a step in itself.
      setMulticityFlightShopProgress(MulticityFlightShopStep.BookTrip);
    }
  }, []);

  const onSubmitForApprovalFailure = () =>
    setSubmitForApprovalFailure({
      Invalid: InvalidEnum.Missing,
      ConnectionResult: ConnectionResultEnum.Invalid,
    });

  const onSubmitForApproval = async () => {
    if (priceQuote && payments && shopRequest) {
      const request = {
        approvalReason: approvalRequestReason || "",
        contactEmail: contactInfo?.email || "",
        multiTicketType:
          fareDetails?.multiTicketType ?? MultiTicketTypeEnum.Single,
        seatedPassengers: selectedPaxIds,
        lapInfants: [],
        paymentInfo: payments[0].value || [],
        policyCompliance:
          priceQuote.corporateTravel?.policyCompliance ??
          corporateTravel?.policyCompliance,
        pricing: priceQuote.itinerary?.sellingPricing,
        restrictions: priceQuote.itinerary?.restrictions,
        shopRequest: shopRequest,
        isApprovalRequired: !isAutoApprovalEnabled,
        fareId: fareDetails?.id ?? "",
        fareDetails: fareDetails as CorpFareDetails,
        slices: tripDetails?.slices,
        requestedAt: formatDateTimeWithTimeZone(new Date()),
        contact: {
          emailAddress: contactInfo?.email ?? "",
          phoneNumber: contactInfo?.phoneNumber ?? "",
        },
      } satisfies FlightsSubmitForApprovalPayload;

      try {
        if (isAutoApprovalEnabled) {
          const requestId = await submitForApproval(request);
          requestId ? onBookFlight() : onSubmitForApprovalFailure();
        } else {
          setSubmitForApproval(request);
        }
      } catch (error) {
        console.error(error);

        onSubmitForApprovalFailure();
      }
    } else {
      onSubmitForApprovalFailure();
    }
  };

  const isPDPEligible =
    priceDropProtection &&
    priceDropProtection.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible;

  const onBookFlight = () => {
    if (selectedTrip.tripId) {
      priceQuote && currentSession && payments && ancillaries
        ? schedulePayment({
            session: currentSession,
            payments,
            ancillaries,
            agentFee: isAgentPortal ? AGENT_FEE : 0,
            approvalRequestReason: approvalRequestReason,
          })
        : scheduleQuote({ agentFee: isAgentPortal ? AGENT_FEE : 0 });
    }
  };

  const openFlightBookPassengerSelection = () =>
    setTravelerWorkflowStep(TravelerSelectStep.TravelerSelect);

  const openContactInfoWorkflow = () => {
    setContactInfoStep(ContactInfoStep.ContactInfoForm);
  };

  const openSeatSelectionWorkflow = () => {
    setSeatSelectionStep(SeatSelectionStep.SeatSelection);
  };

  const openTravelOffersSelection = () => {
    setTravelWalletSelectStep(
      TravelWalletSingleSelectionStep.TravelWalletSelection
    );
  };

  const openApprovalReasonWorkflow = () => {
    setApprovalReasonStep(ApprovalReasonStep.ApprovalReason);
  };

  const openPaymentSelection = () => setOpenPaymentCard(true);

  const incrementCheckoutStep = () => {
    setCheckoutStep((step: any) => {
      switch (step) {
        case MobileFlightBookWorkflowStep.TravelerInformation:
          return step + 2;
        case MobileFlightBookWorkflowStep.Review:
          return step;
        case MobileFlightBookWorkflowStep.ContactInformation:
          if (!isSeatSelection || !seatMapAvailability || !isInPolicyCheckout) {
            if (
              !isCreditAndOfferStackingExperimentV1 &&
              ((isTravelWalletCreditsExperiment ? !!credit : false) ||
                (isTravelWalletOfferExperiment ? !!offers?.length : false))
            ) {
              return step + 2;
            }
            return step + 3;
          }
          return step + 1;

        case MobileFlightBookWorkflowStep.SeatSelection:
          if (isTravelWalletOfferExperiment && (offers?.length || 0) > 0) {
            return step + 1;
          }
          return step + 2;
        case MobileFlightBookWorkflowStep.RewardsAndPayment:
          return isInPolicyCheckout ? step + 2 : step + 1; // skips approval reason step
        default:
          return step + 1;
      }
    });
  };

  const handleGoBack = () => {
    switch (checkoutStep) {
      case MobileFlightBookWorkflowStep.TravelerInformation:
        switch (flightBookType) {
          case FlightBookType.PRICE_FREEZE_EXERCISE:
            const priceFreezeIdFromQuery = new URLSearchParams(
              history.location.search
            ).get(PRICE_FREEZE_ID_QUERY_PARAM);
            history.push({
              pathname: PATH_PRICE_FREEZE_CUSTOMIZE,
              search: queryStringParser.stringify({
                [PRICE_FREEZE_ID_QUERY_PARAM]: priceFreezeIdFromQuery,
              }),
              state: {
                prevPath: PATH_BOOK,
              },
            });
            break;
          case FlightBookType.DEFAULT:
          default:
            populateFlightShopQueryParams({
              history,
              prevPath: PATH_BOOK,
              useHistoryPush: true,
              newQueryParams: {
                flightShopProgress: FlightShopStep.ChooseDeparture,
              },
            });
            setFlightShopProgress(FlightShopStep.ChooseDeparture);
            break;
        }
        break;
      case MobileFlightBookWorkflowStep.ContactInformation:
        // note: skip PricingAndPassengerValidation step when going back from ContactInformation
        setCheckoutStep(MobileFlightBookWorkflowStep.TravelerInformation);
        break;
      case MobileFlightBookWorkflowStep.SeatSelection:
        // note: skip PricingAndPassengerValidation step when going back from ContactInformation
        setCheckoutStep(MobileFlightBookWorkflowStep.ContactInformation);
        break;
      case MobileFlightBookWorkflowStep.TravelOfferSelection:
        if (!isSeatSelection || !seatMapAvailability) {
          setCheckoutStep((step: any) => step - 2);
        } else {
          setCheckoutStep((step: any) => step - 1);
        }
        break;
      case MobileFlightBookWorkflowStep.RewardsAndPayment:
        if (!isSeatSelection || !seatMapAvailability) {
          setCheckoutStep((step: any) =>
            isTravelWalletOfferExperiment && (offers?.length || 0) > 0
              ? step - 1
              : step - 3
          );
        } else {
          setCheckoutStep((step: any) =>
            (isTravelWalletOfferExperiment && (offers?.length || 0) > 0) ||
            !isInPolicyCheckout
              ? step - 1
              : step - 2
          );
        }
        break;
      default:
        setCheckoutStep((step: any) => step - 1);
        break;
    }
  };

  const closeAllPopovers = () => {
    if (isSeatSelection) {
      setTravelerWorkflowStep(TravelerSelectStep.Main);
      setContactInfoStep(ContactInfoStep.Main);
      setSeatSelectionStep(SeatSelectionStep.Main);
      setTravelWalletSelectStep(TravelWalletSingleSelectionStep.Main);
      setOpenPaymentCard(false);
    } else {
      setTravelerWorkflowStep(TravelerSelectStep.Main);
      setContactInfoStep(ContactInfoStep.Main);
      setTravelWalletSelectStep(TravelWalletSingleSelectionStep.Main);
      setOpenPaymentCard(false);
    }
  };

  const handlePriceDropTracking = () =>
    trackEvent({
      eventName: VIEWED_PRICE_DROP_DETAILS,
      properties: {
        page:
          checkoutStep === MobileFlightBookWorkflowStep.Review
            ? "trip_review"
            : "payment_summary",
        refund_type: priceDropRefundType,
      },
    });

  const startSchedulePriceQuoteAndResetPayment = () => {
    scheduleQuote({
      agentFee: isAgentPortal ? AGENT_FEE : 0,
      pollQuoteOnly: true,
    });
    // note: reset selected rewards account and payment method ids
    resetPaymentCardSelectedAccounts();
    setUpdatedInfoOnReview(true);
  };

  const renderPriceBreakdowndropdown = (popoverClassName?: string) => {
    return (
      <PriceBreakdownDropdown
        handlePriceDropTracking={handlePriceDropTracking}
        popoverClassName={popoverClassName}
      />
    );
  };

  useEffect(() => {
    switch (checkoutStep) {
      case MobileFlightBookWorkflowStep.TravelerInformation:
        closeAllPopovers();
        openFlightBookPassengerSelection();
        break;
      case MobileFlightBookWorkflowStep.ContactInformation:
        closeAllPopovers();
        openContactInfoWorkflow();
        break;
      case MobileFlightBookWorkflowStep.SeatSelection:
        closeAllPopovers();
        openSeatSelectionWorkflow();
        break;
      case MobileFlightBookWorkflowStep.TravelOfferSelection:
        closeAllPopovers();
        openTravelOffersSelection();
        break;
      case MobileFlightBookWorkflowStep.RewardsAndPayment:
        closeAllPopovers();
        openPaymentSelection();
        break;
      case MobileFlightBookWorkflowStep.ApprovalReason:
        closeAllPopovers();
        openApprovalReasonWorkflow();
        break;
      default:
        break;
    }
  }, [checkoutStep]);

  // note: it handles the PassengerSelection step logic separately from the previous useEffect;
  // when travelerWorkflowStep is being changed too rapidly (e.g.: changing from Main -> TravelerSelect -> TravelerInfoForm because of hasUserPassengers value)
  // it seems to cause some unexpected behaviours on TravelerSelectWorkflow, which in turn causes the Review screen to be unscrollable
  // https://hopper-jira.atlassian.net/browse/BP-1090
  useEffect(() => {
    if (
      checkoutStep === MobileFlightBookWorkflowStep.TravelerInformation &&
      hasNoUserPassengers
    ) {
      setTravelerWorkflowStep(TravelerSelectStep.TravelerInfoForm);
    }
  }, [checkoutStep, hasNoUserPassengers]);

  const prevMissingPassportError = usePrevious(missingPassport);
  useEffect(() => {
    if (
      hasError &&
      (errorTitles.primaryButtonText === UPDATE_TRAVELERS ||
        errorTitles.secondaryButtonText === EDIT_TRAVELERS) &&
      !missingPassport
    ) {
      setCheckoutStep(MobileFlightBookWorkflowStep.TravelerInformation);
    }
    if (prevMissingPassportError && !missingPassport) {
      if (!!priceQuoteInProgress && isPassportEnhancementExperiment) {
        return;
      } else {
        setCheckoutStep(MobileFlightBookWorkflowStep.TravelerInformation); // goes back selection AFTER passport info is updated from the missing passport info error
      }
    }
  }, [
    hasError,
    errorTitles,
    missingPassport,
    priceQuote,
    priceQuoteInProgress,
  ]);

  useEffect(() => {
    if (finalizedItinerary) {
      if (isPDPEligible) {
        const properties: PriceDropViewedProperties = {
          ...priceDropViewedProperties,
          page:
            checkoutStep === MobileFlightBookWorkflowStep.Review
              ? "trip_review"
              : "payment_summary",
        };
        trackEvent({
          eventName: PRICE_DROP_VIEWED,
          properties,
        });
      }

      const properties: ProductFeedLoadingProperties = {
        feed_type: "cross_sell_hotels",
        feed_placement: "flight_confirmation",
      };
      trackEvent({ eventName: PRODUCT_FEED_LOADING, properties });
      fetchInitialCrossSellHotelAvailability(finalizedItinerary, airports);

      history.push(PATH_BOOK_CONFIRMATION);
    }
  }, [finalizedItinerary]);

  useEffect(() => {
    if (
      checkoutStep === MobileFlightBookWorkflowStep.Review &&
      !!priceQuote &&
      !priceQuoteInProgress &&
      updatedInfoOnReview
    ) {
      if (isSeatSelection && seatMapAvailability) {
        setCheckoutStep(MobileFlightBookWorkflowStep.SeatSelection); // if seats are avail after PQ is run again, bring user back to step to select seats
      } else if (creditToApply) {
        setCheckoutStep(MobileFlightBookWorkflowStep.TravelOfferSelection); // if user has selected a travel wallet item before PQ is run again on pax/contact info update, bring them back to that step
      } else {
        handleGoBack();
      }
      setUpdatedInfoOnReview(false);
    }
  }, [
    checkoutStep,
    priceQuote,
    priceQuoteInProgress,
    updatedInfoOnReview,
    seatMapAvailability,
  ]);

  useEffect(() => {
    isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setUserTcpaConsentPhoneNumber(details?.phoneNumber || "");
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
  }, []);

  useEffect(() => {
    if (
      checkoutStep === MobileFlightBookWorkflowStep.ContactInformation &&
      !!priceQuote
    ) {
      incrementCheckoutStep(); // Increment checkout step AFTER PQ comes back to see if user has seats or not to determine next checkout step
    }
  }, [priceQuote]);

  return (
    <>
      <Box
        className={clsx("corp-mobile-flight-book-workflow-root", {
          "mobile-review-flight-book":
            checkoutStep === MobileFlightBookWorkflowStep.Review,
        })}
      >
        <Header
          className="mobile-flight-book-workflow-header"
          left={
            <BackButton
              className="mobile-flight-book-header-go-back"
              onClick={handleGoBack}
            />
          }
          right={
            checkoutStep === MobileFlightBookWorkflowStep.Review
              ? renderPriceBreakdowndropdown(
                  "mobile-review-flight-book-price-breakdown"
                )
              : renderPriceBreakdowndropdown()
          }
          isMobile
          fullWidth
        />
        <LaunchBanner
          isBusinessLive={
            (sessionInfo as CorpSessionInfo)?.corporateInfo.businessIsLive
          }
          onClick={() => {
            trackEvent({
              eventName: "complete_setup_clicked",
              properties: { entry: "traveler_activation_banner" },
            });
          }}
        />
        {isVoidWindowExperiment && isVoidWindowEligible && <VoidWindowNotice />}
        <Box className="title-summary-container">
          <Typography variant="h6" className="title">
            {TITLE}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {SUBTITLE}
          </Typography>
          <PolicyPill isInPolicy={isInPolicyCheckout} />
        </Box>
        <FlightShopSummaryPanel
          showViewDetailsSection
          prevPath={PATH_BOOK}
          isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
          isVITripSelected={isVITripSelected}
          setOpenVIVariantModal={setOpenVIVariantModal}
          setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
        />
        <FlightBookPassengerSelection
          progress={travelerWorkflowStep}
          setProgress={setTravelerWorkflowStep}
          onGoBack={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileFlightBookWorkflowStep.TravelerInformation:
                handleGoBack();
                break;
              case MobileFlightBookWorkflowStep.Review:
                // note: when the user changes passengers on the final step and then clicks the go-back button,
                // handle it as if the user clicked continue; this is to avoid tricky cases such as having deleted
                // a selected traveler from list -> requires selected travelers to be updated -> needs to re-schedule price quote
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                break;
            }
          }}
          onContinue={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileFlightBookWorkflowStep.Review:
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                incrementCheckoutStep();
                break;
            }
          }}
          selectionScreenHeaderElement={renderPriceBreakdowndropdown()}
          className="b2b"
          onReviewStep={checkoutStep === MobileFlightBookWorkflowStep.Review}
          isMobile
          useLocalIds
          localLapInfantIds={localLapInfantIds}
          setLocalLapInfantIds={setLocalLapInfantIds}
          localTravelerIds={localTravelerIds}
          setLocalTravelerIds={setLocalTravelerIds}
          showVoidWindowNotice={isVoidWindowExperiment && isVoidWindowEligible}
          showPassportAwareness={
            isPassportEnhancementExperiment && isInternationalFlight
          }
          hiddenTravelerIds={[]}
        />
        <ContactInfoWorkflow
          titles={{
            contactInfoTitle: CONTACT_INFO_TITLE,
            contactInfoSubtitle: CONTACT_INFO_SUBTITLE,
            buttonTitle:
              checkoutStep === MobileFlightBookWorkflowStep.Review
                ? CONTACT_INFO_SAVE
                : CONTINUE,
          }}
          isMobile
          progressStep={contactInfoStep}
          setProgressStep={setContactInfoStep}
          contactInfo={contactInfo}
          hasSelectedDisruption={hasSelectedDisruption}
          smsBeenSelected={smsBeenSelected}
          appNotifBeenSelected={appNotifBeenSelected}
          toggleAppNotifOptIn={toggleAppNotifOptIn}
          toggleSMSOptIn={toggleSMSOptIn}
          disruptionOptInEnabled={disruptionOptInEnabled}
          tcpaConsentPhoneNumber={tcpaConsentPhoneNumber}
          onGoBack={() => {
            if (
              checkoutStep === MobileFlightBookWorkflowStep.ContactInformation
            ) {
              handleGoBack();
            }
          }}
          onContactInfoChange={(contactInfoUpdate) => {
            setContact(contactInfoUpdate);
            setContactInfo(
              contactInfoUpdate.email,
              contactInfoUpdate.phoneNumber
            );
            if (
              contactInfoUpdate.email &&
              contactInfoUpdate.phoneNumber &&
              emailRegex.test(contactInfoUpdate.email) &&
              phoneRegex.test(contactInfoUpdate.phoneNumber)
            ) {
              trackEvent({
                eventName: ADD_CONTACT_INFO,
                properties: {},
              });
            }
            !isSeatSelection && incrementCheckoutStep();
          }}
          mobileHeaderElement={renderPriceBreakdowndropdown()}
          className="b2b"
          onContinueClick={() => {
            switch (checkoutStep) {
              case MobileFlightBookWorkflowStep.Review:
                startSchedulePriceQuoteAndResetPayment();
                break;
              default:
                scheduleQuote({
                  agentFee: isAgentPortal ? AGENT_FEE : 0,
                  pollQuoteOnly: true,
                });
                break;
            }
          }}
          loading={customerDetailsLoading}
          bottomContent={
            isVoidWindowExperiment && isVoidWindowEligible ? (
              <VoidWindowNotice />
            ) : undefined
          }
          disruptionDelayThresholdStringInHours={
            disruptionDelayThresholdStringInHours
          }
        />
        {isSeatSelection && (
          <MobileSeatSelection
            title={SEAT_SELECTION_TITLE}
            progressStep={seatSelectionStep}
            setProgressStep={setSeatSelectionStep}
            mobileHeaderElement={renderPriceBreakdowndropdown()}
            onGoBack={() => {
              if (checkoutStep === MobileFlightBookWorkflowStep.SeatSelection) {
                handleGoBack();
              }
            }}
            onContinue={() => incrementCheckoutStep()}
          />
        )}
        {isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1 && (
            <TravelOfferSelection
              progressStep={travelWalletSelectStep}
              mobileHeaderElement={<PriceBreakdownDropdown />}
              isMobile
              onGoBack={() => {
                if (
                  checkoutStep ===
                  MobileFlightBookWorkflowStep.TravelOfferSelection
                ) {
                  handleGoBack();
                }
              }}
              onContinueClick={() => {
                incrementCheckoutStep();
                isTravelWalletPaymentOnly &&
                  setTravelWalletSelectStep(
                    TravelWalletSingleSelectionStep.Main
                  );
              }}
            />
          )}
        <Box className="pricing-breakdown-header-wrapper">
          <Box className="pricing-breakdown-header-container">
            <Typography className="header" variant="h6">
              {CHECKOUT_PRICE_BREAKDOWN_HEADER}
            </Typography>
          </Box>
        </Box>
        {priceDropProtection && isPDPEligible && (
          <PriceDropProtection
            priceDropProtection={priceDropProtection}
            onClick={handlePriceDropTracking}
          />
        )}
        <PriceBreakdown
          isMobile
          className={clsx({ "less-padding": isTreesModalExperiment })}
        />
        {isTreesModalExperiment && (
          <TreesModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            modalButtonCopyStartIcon={
              <Icon className="trees-icon" name={IconName.TreesIcon} />
            }
            isMobile
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
          />
        )}
        {!isInPolicyCheckout && (
          <>
            <ApprovalReasonWorkflow
              buttonText="Review my trip"
              progressStep={approvalReasonStep}
              setProgressStep={setApprovalReasonStep}
              mobileHeaderElement={<PriceBreakdownDropdown />}
              onGoBack={handleGoBack}
              className="b2b approval-reason-workflow"
              onContinueClick={(reason) => {
                incrementCheckoutStep();
                setApprovalRequestReason(reason);
              }}
              corporateTravel={corporateTravel}
            />
            <Box p="16px 16px 50px 16px">
              <ApprovalBanner
                isApprovalRequired={
                  isApprovalsV2Enabled
                    ? policies?.settings && policies.settings.isApprovalRequired
                    : !isAutoApprovalEnabled
                }
                isApprovalsV2Enabled={isApprovalsV2Enabled}
              />
            </Box>
          </>
        )}
        <FlightBookMobileButton
          hasContactInfo={!!contactInfo}
          openContactInfo={() =>
            setContactInfoStep(ContactInfoStep.ContactInfoForm)
          }
          isBookingValid={isBookingValid}
          onConfirmAndBook={() => {
            if (isInPolicyCheckout || isApprovalsV2Enabled) {
              onBookFlight();
            } else {
              onSubmitForApproval();
            }
          }}
          // TODO: simplify/trim FlightBookMobileButton logic
          showPaymentStep
          onApplyRewards={() => {}}
          isInPolicy={isInPolicyCheckout}
        />
      </Box>
      <MobilePaymentCardPopover
        openPaymentCard={openPaymentCard}
        disabled={!isBookingValid}
        onClose={() => setOpenPaymentCard(false)}
        onConfirm={() => {
          incrementCheckoutStep();
          setOpenPaymentCard(false);
        }}
        onGoBack={() => {
          if (checkoutStep === MobileFlightBookWorkflowStep.RewardsAndPayment) {
            handleGoBack();
          }
        }}
        mobileHeaderElement={renderPriceBreakdowndropdown()}
        isInPolicy={isInPolicyCheckout}
      />
      <LoadingPopup
        classes={["mobile-price-quote-loading-popup"]}
        open={isWaitingPriceQuote || seatMapLoading}
        message={
          isWaitingValidation &&
          checkoutStep ===
            MobileFlightBookWorkflowStep.PricingAndPassengerValidation
            ? PRICE_QUOTE_PASSENGER_VALIDATION_MESSAGE
            : PRICE_QUOTE_MESSAGE
        }
        indicator={B2BSpinner}
        indicatorSize="small"
        verticalAlignment="center"
        fullScreen
      />
      {openVIVariantModal && openVIVariantModal !== "reminder" && (
        <VirtualInterliningModal
          isMobile={true}
          variant={openVIVariantModal}
          fareDetails={tripDetails.fareDetails}
          airports={airports}
          isOpen
          onClose={() => setOpenVIVariantModal(false)}
        />
      )}
      {openMultipleAirlinesFares && (
        <MultipleAirlinesFareModal
          isOpen={openMultipleAirlinesFares}
          tripDetails={tripDetails}
          airports={airports}
          onClose={() => setOpenMultipleAirlinesFares(false)}
        />
      )}
    </>
  );
};

interface IMobilePaymentCardPopoverProps {
  openPaymentCard: boolean;
  disabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onGoBack: () => void;
  mobileHeaderElement?: JSX.Element;
  isInPolicy: boolean;
}

const MobilePaymentCardPopover = (props: IMobilePaymentCardPopoverProps) => {
  const {
    openPaymentCard,
    disabled = true,
    onClose,
    onConfirm,
    onGoBack,
    mobileHeaderElement,
    isInPolicy,
  } = props;

  return (
    <MobilePopoverCard
      open={openPaymentCard}
      className={clsx("flight-payment-card-popup", "b2b")}
      fullScreen
      onClose={onClose}
      topLeftButton={
        <BackButton
          className="flight-payment-card-popup-back-button"
          onClick={onGoBack}
        />
      }
      headerElement={mobileHeaderElement}
    >
      <PaymentCard />
      {!disabled && (
        <MobileFloatingButton
          className="flight-book-review-trip-button"
          onClick={onConfirm}
          wrapperClassName="b2b"
        >
          {isInPolicy ? REVIEW_MY_TRIP_TEXT : CONTINUE}
        </MobileFloatingButton>
      )}
    </MobilePopoverCard>
  );
};
