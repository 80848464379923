import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { Box, Button, Typography, Divider } from "@material-ui/core";
import {
  IContactInfo,
  TravelerSelectStep,
  CheckoutStepper,
  ContactInfoForm,
  ButtonWrap,
  Icon,
  IconName,
  emailRegex,
  phoneRegex,
  ActionButton,
  ActionLink,
  InformationalModal as TreesModal,
  PolicyBanner,
  ApprovalBanner,
  ApprovalReasonTextarea,
  LaunchBanner,
  TreesConfirmation,
  formatDateTimeWithTimeZone,
  VoidWindowNotice,
} from "halifax";
import clsx from "clsx";
import { Location } from "history";
import { RouteComponentProps } from "react-router";
import {
  ADD_CONTACT_INFO,
  PriceDropProtectionEnum,
  PriceDropViewedProperties,
  PRICE_DROP_VIEWED,
  VIEWED_PRICE_DROP_DETAILS,
  ITravelerStepErrors,
  AIR_SAVE_AND_CONTINUE_CLICKED,
  FlightBookType,
  CallState,
  CorpSessionInfo,
  TripCategory,
  FlightsSubmitForApprovalPayload,
  CorpFareDetails,
  MultiTicketTypeEnum,
  InvalidEnum,
  ConnectionResultEnum,
} from "redmond";
import { SeatSelection } from "../../SeatSelection";
import "./styles.scss";
import {
  FlightShopSummaryPanel,
  FlightShopProgressBar,
} from "../../../../shop/components";
import { FlightFreezeProgressBar } from "../../../../freeze/components";
import { CorpDesktopFlightBookWorkflowConnectorProps } from "./container";
import {
  PriceBreakdown,
  FlightBookPassengerSelection,
  PaymentCard,
  FrozenPriceSummary,
  PriceDropProtection,
  AGENT_FEE,
} from "../../";
import {
  BOOK_BUTTON_TEXT,
  CONTACT_INFO_SUBTITLE,
  CONTACT_INFO_HELPER_TEXT,
  REQUIRED_DETAILS_TEXT,
  SEAT_SELECTION_TITLE,
  CONTACT_INFO_TITLE_NO_STEP,
  CHECKOUT_PRICE_BREAKDOWN_HEADER,
  TRAVELER_INFO_TITLE,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
} from "../../capone/DesktopFlightBookWorkflow/textConstants";
import {
  PATH_BOOK,
  PATH_BOOK_CONFIRMATION,
  PATH_HOME,
} from "../../../../../utils/urlPaths";
import { ClientContext } from "../../../../../App";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  PRICE_FREEZE,
  SEAT_SELECTION,
  getExperimentVariant,
  useExperiments,
  TREES_MODAL_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  SEATS_UX_OPTIMIZATION,
  PASSPORT_ENHANCEMENT,
  VOID_WINDOW_EXPERIMENT,
  CREDIT_OFFER_STACKING_V1,
} from "../../../../../context/experiments";
import { fetchCustomerDetails } from "../../../../../api/v0/customer/fetchCustomerDetails";
import {
  FlightShopStep,
  MulticityFlightShopStep,
} from "../../../../shop/reducer";
import { TravelOfferSelection } from "../../TravelOfferSelection";
import { DO_NOT_APPLY_REWARDS_KEY } from "@capone/common/src";
import { SEATING } from "../../../reducer/selectors/textConstants";
import { MulticityFlightShopProgressBar } from "../../../../shop/components/MulticityFlightShopProgressBar";
import {
  IVirtualInterliningVariant,
  VirtualInterliningModal,
} from "../../../../shop/components/VirtualIinterliningModal";
import { MultipleAirlinesFareModal } from "../../../../shop/components/MultipleAirlinesFareModal";
import { OptIn } from "../../AlertOptIn";
import { PassportAwarenessCard } from "../../PassportAwarenessCard/component";
import { CheckoutSteps } from "../../../reducer";
import { SUBMIT_FOR_APPROVAL_TEXT } from "./textConstants";
import { AddOnCustomize } from "../../../../shop/components/addOnComponents";
import submitForApproval from "../../../../../api/v0/book/book-flow/submitForApproval";
import { useExperimentsById } from "@capone/experiments";

export interface ICorpDesktopFlightBookWorkflowProps
  extends RouteComponentProps,
    CorpDesktopFlightBookWorkflowConnectorProps {}

export const CorpDesktopFlightBookWorkflow = ({
  setContactInfo,
  scheduleQuote,
  finalizedItinerary,
  history,
  selectedTrip,
  confirmationEmail,
  confirmationPhoneNumber,
  isConfirmAndBookReady,
  priceDifferenceAcknowledged,
  schedulePayment,
  currentSession,
  payments,
  ancillaries,
  priceDropProtection,
  priceDropViewedProperties,
  priceQuote,
  hasDifference,
  isTravelerStepComplete,
  isPriceFreezeExerciseEnabled,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setSelectedSeats,
  isWaitingPriceQuote,
  priceQuoteErrors,
  setQuote,
  flightShopProgress,
  setFlightShopProgress,
  flightBookType,
  fetchInitialCrossSellHotelAvailability,
  fetchTravelWalletCreditHistory,
  fetchApplicableTravelWalletItems,
  airports,
  policyLimit,
  corporateTravel,
  bookingProgressList,
  isCustomizePageMarketplaceEnabled,
  isFlightBookWithAncillariesEnabled,
  isFlightBookWithAncillariesActive,
  isOptionSelectionComplete,
  fetchAncillaryOfferCallState,
  fetchAncillaryOffer,
  getCheckoutStepNumber,
  tripCategory,
  hasActiveRefundableFare,
  resetFetchAncillaryOfferState,
  isVoidWindowEligible,
  isTravelWalletPaymentOnly,
  multicityFlightShopProgress,
  setMulticityFlightShopProgress,
  smsBeenSelected,
  appNotifBeenSelected,
  hasSelectedDisruption,
  setUserTcpaConsentPhoneNumber,
  disruptionOptInEnabled,
  nonFDAdisruptionOptInEnabled,
  isInternationalTrip,
  isCfarMulticityEnabled,
  tripDetails,
  disruptionDelayThreshold,
  isDisruptionProtectionEnabled,
  fareDetails,
  selectedPaxIds,
  shopRequest,
  setSubmitForApprovalFailure,
  setSubmitForApproval,
  viewedTripSummaryProperties,
}: ICorpDesktopFlightBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal, isAutoApprovalEnabled, policies } =
    clientContext;
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    phone: false,
    email: false,
    travelerSelect: false,
  });

  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: confirmationPhoneNumber || "",
    email: confirmationEmail || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    useState<boolean>(true);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [disableEditTravelerInfo, setDisableEditTravelerInfo] = useState(false);

  const [treeModalOpen, setTreeModalOpen] = useState(false);

  const isFirstStepCompletedInCheckout = isTravelerStepComplete;

  const expState = useExperiments();

  const seatSelectionGroup = getExperimentVariant(
    expState.experiments,
    SEAT_SELECTION
  );
  const priceFreezeGroup = getExperimentVariant(
    expState.experiments,
    PRICE_FREEZE
  );

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );

  const [approvalRequestReason, setApprovalRequestReason] = useState<
    string | undefined
  >();

  const isPriceFreeze = useMemo(
    () => isPriceFreezeExerciseEnabled && priceFreezeGroup === AVAILABLE,
    [priceFreezeGroup, isPriceFreezeExerciseEnabled]
  );

  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const passportEnhancementExperiment = getExperimentVariant(
    expState.experiments,
    PASSPORT_ENHANCEMENT
  );

  const isPassportEnhancementExperiment = useMemo(() => {
    return passportEnhancementExperiment === AVAILABLE;
  }, [passportEnhancementExperiment]);

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const isSeatsUXOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, SEATS_UX_OPTIMIZATION) ===
      AVAILABLE,
    [expState]
  );

  const isVoidWindowExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, VOID_WINDOW_EXPERIMENT) ===
      AVAILABLE,
    [expState]
  );

  const isApprovalsV2Enabled =
    useExperimentsById("corp-approvals-v2")?.variant === "m2";

  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected = tripDetails.fareDetails[0]?.slices.some((slice) =>
    slice.fareDetails.segments.some((segment) => segment.isSelfTransferLayover)
  );

  // Follows patterns established elsewhere, but the flag is not (yet) needed in this context.
  const [openVIVariantModal, setOpenVIVariantModal] = useState<
    IVirtualInterliningVariant | "reminder" | false
  >(false);

  // Follows patterns established elsewhere, but the flag is not (yet) needed in this context.
  const [openMultipleAirlinesFares, setOpenMultipleAirlinesFares] =
    useState(false);

  const isInPolicyCheckout =
    // TODO(replat): add back after BE work is done
    // priceQuote?.corporateTravel.policyCompliance.isInPolicy ??
    corporateTravel?.policyCompliance.isInPolicy ?? true;

  const isMulticity = useMemo(() => {
    return tripCategory === TripCategory.MULTI_CITY;
  }, [tripCategory]);

  const onSubmitForApprovalFailure = () =>
    setSubmitForApprovalFailure({
      Invalid: InvalidEnum.Missing,
      ConnectionResult: ConnectionResultEnum.Invalid,
    });

  const onSubmitForApproval = async () => {
    if (priceQuote && payments && shopRequest) {
      const request = {
        approvalReason: approvalRequestReason || "",
        contactEmail: contactInfo?.email || "",
        multiTicketType:
          fareDetails?.multiTicketType ?? MultiTicketTypeEnum.Single,
        seatedPassengers: selectedPaxIds,
        lapInfants: [],
        paymentInfo: payments[0].value || [],
        policyCompliance:
          priceQuote.corporateTravel?.policyCompliance ??
          corporateTravel?.policyCompliance,
        pricing: priceQuote.itinerary?.sellingPricing,
        restrictions: priceQuote.itinerary?.restrictions,
        shopRequest: shopRequest,
        isApprovalRequired: !isAutoApprovalEnabled,
        fareId: fareDetails?.id ?? "",
        fareDetails: fareDetails as CorpFareDetails,
        slices: tripDetails?.slices,
        requestedAt: formatDateTimeWithTimeZone(new Date()),
        contact: {
          emailAddress: contactInfo?.email ?? "",
          phoneNumber: contactInfo?.phoneNumber ?? "",
        },
      } satisfies FlightsSubmitForApprovalPayload;

      try {
        if (isAutoApprovalEnabled) {
          const requestId = await submitForApproval(request);

          requestId ? onBookFlight() : onSubmitForApprovalFailure();
        } else {
          setSubmitForApproval(request);
        }
      } catch (error) {
        console.error(error);

        onSubmitForApprovalFailure();
      }
    } else {
      onSubmitForApprovalFailure();
    }
  };

  /*
    note: the proper way to handle the filtering logic is by passing the feature flag to redux, but since we currently have
    a plan to refactor that pattern (e.g. https://hopper-jira.atlassian.net/browse/BF-874), a workaround is used here for now
  */

  const isSeatSelectionGroupEnabled =
    seatSelectionGroup === AVAILABLE ||
    (corporateTravel?.policyCompliance.isInPolicy && isAutoApprovalEnabled);

  const bookingProgressSteps = bookingProgressList.filter(
    (progress) => progress.name !== SEATING || isSeatSelectionGroupEnabled
  );

  const disruptionDelayThresholdStringInHours =
    disruptionDelayThreshold === 180 ? "3" : "2";

  const onBookFlight = () => {
    if (selectedTrip.tripId) {
      priceQuote &&
      currentSession &&
      payments &&
      ancillaries &&
      (!hasDifference || priceDifferenceAcknowledged)
        ? schedulePayment({
            session: currentSession,
            payments,
            ancillaries,
            agentFee: isAgentPortal ? AGENT_FEE : 0,
            isPriceFreezePurchase: undefined,
            approvalRequestReason: approvalRequestReason,
          })
        : scheduleQuote({ agentFee: isAgentPortal ? AGENT_FEE : 0 });
    }
  };

  const isPDPEligible =
    priceDropProtection &&
    priceDropProtection.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible;

  useEffect(() => {
    if (!shopRequest && !isInPolicyCheckout) {
      history.push(PATH_HOME);
    }
  }, [shopRequest, isInPolicyCheckout]);

  useEffect(() => {
    if (finalizedItinerary) {
      fetchInitialCrossSellHotelAvailability(finalizedItinerary, airports);
      history.push(PATH_BOOK_CONFIRMATION);
      if (isPDPEligible) {
        const properties: PriceDropViewedProperties = {
          ...priceDropViewedProperties,
          page: "flight_book",
        };
        trackEvent({
          eventName: PRICE_DROP_VIEWED,
          properties,
        });
      }
    }
  }, [finalizedItinerary]);

  useEffect(() => {
    if (priceQuoteErrors.length > 0) {
      setDisableEditTravelerInfo(false);
    }
  }, [priceQuoteErrors]);

  useEffect(() => {
    // note: this handles if on the booking error modals, the button gets PQ again but does not bring user to next step (such as LapInfantsUnsupported error)
    if (priceQuote && priceQuoteErrors.length === 0 && isTravelerStepComplete) {
      setDisableEditTravelerInfo(true);
    }
  }, [priceQuote, priceQuoteErrors]);

  useEffect(() => {
    if (
      !isMulticity &&
      isFlightBookWithAncillariesEnabled &&
      fetchAncillaryOfferCallState === CallState.NotCalled
    ) {
      fetchAncillaryOffer({
        preserveCfarId: hasActiveRefundableFare,
      });
    }
  }, [
    isFlightBookWithAncillariesEnabled,
    fetchAncillaryOfferCallState,
    hasActiveRefundableFare,
  ]);

  useEffect(() => {
    if (isCfarMulticityEnabled && isMulticity) {
      // Multi-city tracks flight shop progress a little differently. Book is a step in itself.
      setMulticityFlightShopProgress(MulticityFlightShopStep.BookTrip);
    }
  }, []);

  useEffect(() => {
    isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setUserTcpaConsentPhoneNumber(details?.phoneNumber || "");
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();

    // note: resolve any potential loop caused by flightShopStep (if it exists)
    if (isMulticity) {
      const prevMulticityFlightShopProgress:
        | MulticityFlightShopStep
        | undefined = (
        history.location as Location<{
          multicityFlightShopProgress: MulticityFlightShopStep;
        }>
      ).state?.multicityFlightShopProgress;
      if (
        isMulticity &&
        prevMulticityFlightShopProgress !== undefined &&
        multicityFlightShopProgress !== prevMulticityFlightShopProgress
      ) {
        setMulticityFlightShopProgress(prevMulticityFlightShopProgress);
      }
    } else {
      const prevFlightShopProgress: FlightShopStep | undefined = (
        history.location as Location<{ flightShopProgress: FlightShopStep }>
      ).state?.flightShopProgress;
      if (
        !isMulticity &&
        prevFlightShopProgress !== undefined &&
        flightShopProgress !== prevFlightShopProgress
      ) {
        setFlightShopProgress(prevFlightShopProgress);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      if (isFlightBookWithAncillariesActive) {
        resetFetchAncillaryOfferState({
          preserveCfarId: hasActiveRefundableFare,
        });
      }
    };
  }, [isFlightBookWithAncillariesActive, hasActiveRefundableFare]);

  const renderLogo = (
    <ButtonWrap
      className="logo"
      onClick={() => {
        history.push(PATH_HOME);
      }}
    >
      {clientContext.logo}
    </ButtonWrap>
  );

  const handleReasonChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setApprovalRequestReason(e.target.value);
    },
    []
  );

  return (
    <Box
      className={clsx(
        "desktop-flight-book-workflow-root",
        "home-screen-redesign"
      )}
    >
      <CheckoutStepper
        steps={bookingProgressSteps}
        headerCopy={BOOK_BUTTON_TEXT}
        subHeaderCopy={REQUIRED_DETAILS_TEXT}
        logo={renderLogo}
        className={clsx("b2b", "combined-step")}
      >
        <LaunchBanner
          isBusinessLive={
            (sessionInfo as CorpSessionInfo)?.corporateInfo.businessIsLive
          }
          onClick={() => {
            trackEvent({
              eventName: "complete_setup_clicked",
              properties: { entry: "traveler_activation_banner" },
            });
          }}
        />
      </CheckoutStepper>
      {isCustomizePageMarketplaceEnabled && (
        <Box className="flight-book-progress-bar-wrapper">
          {!isMulticity && flightBookType === FlightBookType.DEFAULT && (
            <FlightShopProgressBar
              useCustomizeStep={true}
              prevPath={PATH_BOOK}
              usePush={true}
            />
          )}
          {isMulticity && <MulticityFlightShopProgressBar />}
          {flightBookType === FlightBookType.PRICE_FREEZE_EXERCISE && (
            <FlightFreezeProgressBar />
          )}
        </Box>
      )}
      <Box
        className={clsx("desktop-flight-book-workflow-container", {
          "with-progress-bar": isCustomizePageMarketplaceEnabled,
        })}
      >
        <Box className={clsx("checkout-template-column", "left")}>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "flight"
            )}
          >
            <FlightShopSummaryPanel
              showViewDetailsSection
              prevPath={PATH_BOOK}
              disableChangeFlight={isPriceFreezeExerciseEnabled}
              isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
              isVITripSelected={isVITripSelected}
              setOpenVIVariantModal={setOpenVIVariantModal}
              setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
            />
          </Box>
          {isInternationalTrip && isPassportEnhancementExperiment && (
            <Box className="checkout-template-card-content-container">
              <PassportAwarenessCard />
            </Box>
          )}

          {!isMulticity &&
            isTravelWalletOfferExperiment &&
            !isCreditAndOfferStackingExperimentV1 && <TravelOfferSelection />}

          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "traveler"
            )}
          >
            <Box className={clsx("checkout-template-description")}>
              <Typography
                variant="h2"
                className={clsx("checkout-template-title")}
              >
                {TRAVELER_INFO_TITLE(
                  getCheckoutStepNumber(CheckoutSteps.ADD_TRAVELERS)
                )}
              </Typography>
            </Box>
            <Divider className="traveler-info-container-divider" />
            <FlightBookPassengerSelection
              progress={travelerWorkflowStep}
              setProgress={setTravelerWorkflowStep}
              combinedStep
              disabled={disableEditTravelerInfo}
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              saveButtonClicked={saveButtonClicked}
              hiddenTravelerIds={
                (sessionInfo as CorpSessionInfo)?.corporateInfo
                  .hiddenTravelerIds ?? []
              }
            />
            <ContactInfoForm
              disabled={disableEditTravelerInfo}
              title={CONTACT_INFO_TITLE_NO_STEP}
              subtitle={CONTACT_INFO_SUBTITLE}
              contactInfo={contactInfo}
              onContactInfoChange={(info: IContactInfo) => {
                setContact(info);
                setContactInfo(info.email, info.phoneNumber);
                if (
                  info.email &&
                  info.phoneNumber &&
                  emailRegex.test(info.email) &&
                  phoneRegex.test(info.phoneNumber)
                ) {
                  trackEvent({
                    eventName: ADD_CONTACT_INFO,
                    properties: {},
                  });
                }
              }}
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              loading={customerDetailsLoading}
              disruptionOptInPresent={
                hasSelectedDisruption && disruptionOptInEnabled
              }
            />
            <OptIn
              disabled={disableEditTravelerInfo}
              smsBeenSelected={smsBeenSelected}
              appNotifBeenSelected={appNotifBeenSelected}
              hasSelectedDisruption={hasSelectedDisruption}
              disruptionOptInEnabled={disruptionOptInEnabled}
              nonFDAdisruptionOptInEnabled={nonFDAdisruptionOptInEnabled}
              disrutpionDelayThresholdStringInHours={
                disruptionDelayThresholdStringInHours
              }
            />
            {!disableEditTravelerInfo && (
              <ActionButton
                className={clsx(
                  "traveler-select-workflow-button",
                  "traveler-step-cta"
                )}
                message="Save and continue"
                onClick={() => {
                  if (isFirstStepCompletedInCheckout) {
                    trackEvent({
                      eventName: AIR_SAVE_AND_CONTINUE_CLICKED,
                      properties: {
                        has_form_errors: false,
                      },
                    });
                    scheduleQuote({
                      agentFee: isAgentPortal ? AGENT_FEE : 0,
                      pollQuoteOnly: true,
                    });
                    setDisableEditTravelerInfo(true);
                    setShowErrors({
                      phone: false,
                      email: false,
                      travelerSelect: false,
                    });
                  } else {
                    trackEvent({
                      eventName: AIR_SAVE_AND_CONTINUE_CLICKED,
                      properties: {
                        has_form_errors: true,
                        traveler_error: !isTravelerStepComplete,
                        email_error:
                          !contactInfo || !emailRegex.test(contactInfo.email),
                        phone_error:
                          !contactInfo ||
                          !phoneRegex.test(contactInfo.phoneNumber),
                      },
                    });
                    setShowErrors({
                      phone:
                        !contactInfo ||
                        !phoneRegex.test(contactInfo.phoneNumber),
                      email:
                        !contactInfo || !emailRegex.test(contactInfo.email),
                      travelerSelect: !isTravelerStepComplete,
                    });
                    if (!saveButtonClicked) {
                      setSaveButtonClicked(true);
                    }
                  }
                }}
                defaultStyle="h4r-primary"
              />
            )}
            {disableEditTravelerInfo && (
              <ActionLink
                className={clsx("traveler-step-cta", "b2b")}
                content={
                  <Box className="edit-traveler-link">
                    <Typography variant="body1">
                      Edit Traveler Information
                    </Typography>
                    <Icon
                      aria-hidden
                      className="icon-end"
                      name={IconName.B2BEditPencil}
                      ariaLabel=""
                    />
                  </Box>
                }
                onClick={() => {
                  // reset the price quote since it will be called again
                  setQuote(null);
                  setDisableEditTravelerInfo(false);
                  setSelectedPaymentMethodId({
                    paymentMethodId: "",
                    accountId: undefined,
                    multiTicketType:
                      viewedTripSummaryProperties?.multi_ticket_type,
                  });
                  setSelectedRewardsAccountReferenceId(
                    DO_NOT_APPLY_REWARDS_KEY
                  );
                  setSelectedSeats([]);
                }}
              />
            )}
            <Box className={clsx("contact-info-helper-container")}>
              <Typography
                variant="body2"
                className={clsx("contact-info-helper-text")}
              >
                {CONTACT_INFO_HELPER_TEXT}
              </Typography>
            </Box>
          </Box>
          {seatSelectionGroup === AVAILABLE &&
            (isInPolicyCheckout ||
              isAutoApprovalEnabled ||
              isApprovalsV2Enabled) && (
              <SeatSelection
                title={SEAT_SELECTION_TITLE(
                  getCheckoutStepNumber(CheckoutSteps.SEATING)
                )}
                disabled={
                  !isFirstStepCompletedInCheckout ||
                  !disableEditTravelerInfo ||
                  // note: in the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting, the seat map data is persisted while refetching a new price quote
                  (isWaitingPriceQuote && !isFlightBookWithAncillariesActive)
                }
              />
            )}
          {(!isMulticity || isCfarMulticityEnabled) &&
            isFlightBookWithAncillariesActive && (
              <Box
                className={clsx(
                  "checkout-template-card-content-container",
                  "ancillary"
                )}
              >
                <AddOnCustomize
                  tripCategory={tripCategory}
                  isDisruptionProtectionEnabled={isDisruptionProtectionEnabled}
                  disableCollapse={true}
                  useFlightCheckoutVariant={true}
                />
              </Box>
            )}
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "credit"
            )}
          >
            <PaymentCard
              disabled={
                !isFirstStepCompletedInCheckout ||
                !disableEditTravelerInfo ||
                (isFlightBookWithAncillariesActive &&
                  !isOptionSelectionComplete) ||
                (!isCreditAndOfferStackingExperimentV1 &&
                  isTravelWalletPaymentOnly)
              }
              className={clsx({ "less-padding": isTreesModalExperiment })}
            />
            {isTreesModalExperiment && (
              <TreesModal
                image={TreesConfirmation}
                header={TREES_MODAL_HEADER}
                title={TREES_MODAL_TITLE}
                onClose={() => setTreeModalOpen(false)}
                subtitle={TREES_MODAL_SUBTITLE}
                icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
                openModal={treeModalOpen}
                setOpenModal={setTreeModalOpen}
                modalLinkCopy={TREES_MODAL_CTA_TEXT}
                modalButtonCopyStartIcon={
                  <Icon className="trees-icon" name={IconName.TreesIcon} />
                }
                boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
              />
            )}
          </Box>
        </Box>
        <Box className={clsx("checkout-template-column", "right")}>
          <Box className="checkout-template-column-right-wrapper">
            {!isMulticity && isPriceFreeze && (
              <Box
                className={clsx(
                  "checkout-template-card-content-container",
                  "price-freeze-details"
                )}
              >
                <FrozenPriceSummary />
              </Box>
            )}
            <Box
              className={clsx(
                "checkout-template-card-content-container",
                "pricing-breakdown"
              )}
            >
              <PolicyBanner isInPolicy={isInPolicyCheckout} />
              <Box className="pricing-breakdown-header-container">
                <Typography className="header" variant="h2">
                  {CHECKOUT_PRICE_BREAKDOWN_HEADER}
                </Typography>
              </Box>
              {!isMulticity && priceDropProtection && isPDPEligible && (
                <PriceDropProtection
                  priceDropProtection={priceDropProtection}
                  onClick={() =>
                    trackEvent({
                      eventName: VIEWED_PRICE_DROP_DETAILS,
                      properties: {
                        page: "flight_book",
                      },
                    })
                  }
                />
              )}
              <Box className={clsx("payment-break-down")}>
                <PriceBreakdown isMobile={false} />
              </Box>
              {!isInPolicyCheckout && (
                <>
                  <ApprovalReasonTextarea
                    value={approvalRequestReason ?? ""}
                    className="approval-reason"
                    onChange={handleReasonChange}
                    corporateTravel={corporateTravel}
                    productType="flight"
                    tripType={tripCategory}
                    limit={policyLimit}
                  />
                  <ApprovalBanner
                    isApprovalRequired={
                      isApprovalsV2Enabled
                        ? policies?.settings &&
                          policies.settings.isApprovalRequired
                        : !isAutoApprovalEnabled
                    }
                    isApprovalsV2Enabled={isApprovalsV2Enabled}
                  />
                </>
              )}
              <Box className="confirm-book-button-container">
                <Button
                  className="confirm-book-button"
                  disabled={
                    !isConfirmAndBookReady ||
                    (!isInPolicyCheckout && !approvalRequestReason)
                  }
                  onClick={() => {
                    isInPolicyCheckout || isApprovalsV2Enabled
                      ? onBookFlight()
                      : onSubmitForApproval();
                  }}
                >
                  {isInPolicyCheckout ||
                  isAutoApprovalEnabled ||
                  isApprovalsV2Enabled
                    ? BOOK_BUTTON_TEXT
                    : SUBMIT_FOR_APPROVAL_TEXT}
                </Button>
              </Box>
            </Box>
            {isVoidWindowExperiment && isVoidWindowEligible && (
              <VoidWindowNotice />
            )}
          </Box>
        </Box>
      </Box>
      {openVIVariantModal && openVIVariantModal !== "reminder" && (
        <VirtualInterliningModal
          isMobile={false}
          variant={openVIVariantModal}
          fareDetails={tripDetails.fareDetails}
          airports={airports}
          isOpen
          onClose={() => setOpenVIVariantModal(false)}
        />
      )}
      {openMultipleAirlinesFares && (
        <MultipleAirlinesFareModal
          isOpen={openMultipleAirlinesFares}
          tripDetails={tripDetails}
          airports={airports}
          onClose={() => setOpenMultipleAirlinesFares(false)}
        />
      )}
    </Box>
  );
};
