import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Experiment, ExperimentState, ExperimentVariant } from "redmond";
import { fetchActiveExperiments } from "../api/v0/experiments/fetchExperiments";

// Active Experiments
export const CFAR = "c1-fintech-cfar";
// todo create chfar own active experiment - https://hopper-jira.atlassian.net/browse/FLEX-3012
export const CHFAR = "c1-fintech-chfar-v0";
export const REFUNDABLE_FARES = "c1-fintech-cfar-refundable-fares";
export const CFAR_SOCIAL = "c1-fintech-cfar-social";
export const DISRUPTION = "c1-fintech-disruption";
export const ANCILLARY_MARKETPLACE = "c1-fintech-ancillary-marketplace";
export const PRICE_FREEZE = "c1-fintech-pricefreeze";
export const PRICE_FREEZE_NEW_REVIEW_CTA = "c1-air-price-freeze-new-review-cta";
export const PRICE_FREEZE_SHOW_DURATIONS = "c-1-price-freeze-show-durations";
export const SIMILAR_FLIGHTS = "c1-fintech-similarflights";
export const SEAT_SELECTION = "c1-marketplace-seatselection";
export const FLIGHT_SHOP = "c1-foundations-flightshop";
export const MOBILE_PREDICTION = "c1-marketplace-mobileprediction";
export const PRICE_FREEZE_VOID_WINDOW = "c1-fintech-apf-void-window";
export const PRICE_FREEZE_PREDICTION_COPY = "c1-price-freeze-prediction-copy";
export const PRICE_FREEZE_USER_SELECTED_DURATION =
  "c1-fintech-pf-user-selected-duration";
export const PRICE_FREEZE_BACK_BUTTON_AIR =
  "c1-fintech-pf-air-back-button";
export const PRICE_FREEZE_USER_SELECTED_DURATION_COPY =
  "c1-fintech-pf-user-selected-duration-copy";
export const PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY =
  "c1-fintech-pf-user-selected-duration-display";
export const PRICE_FREEZE_DEFAULT_DURATIONS =
  "c1-air-price-freeze-default-durations";
export const PRICE_FREEZE_TRAVEL_CREDITS = "c1-price-freeze-travel-credits";
export const AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE =
  "AirPriceFreezeOnlyFreezeQuotedPrice";
export const PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER =
  "c1-fintech-pf-user-selected-duration-no-slider";
export const CFAR_MULTI_CITY = "c1-fintech-cfar-multi-city";
export const TREES_MODAL_EXPERIMENT = "c1-marketplace-trees";
export const INTERNATIONAL_NGS_EXPERIMENT = "c1-marketplace-international-ngs";
export const TRAVEL_WALLET_OFFER_EXPERIMENT =
  "c1-marketplace-travel-wallet-offers";
export const CASH_VALUEPROP_EXPERIMENT = "c1-marketplace-cash-valueprop-merch";
export const TRAVEL_WALLET_CREDITS_EXPERIMENT =
  "c1-marketplace-travel-wallet-credits";
export const PRICE_MATCH_CREDITS_EXPERIMENT =
  "c1-marketplace-price-match-credits";
export const MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT =
  "c1-marketplace-mobile-homescreen-redesign";
export const POST_BOOKING_OFFER_EXPERIMENT =
  "c1-fintech-disruption-air-postbookingoffer";
export const POST_BOOKING_OFFER_EXPERIMENT_REWARDS =
  "c1-fintech-disruption-air-postbookingoffer-rewards";
export const DISRUPTION_AIR_SUMMER_COPY =
  "c1-fintech-disruption-air-summer-2024-copy";
export const PRICE_DROP_CREDIT = "c1-fintech-price-drop-credit";
export const PRICE_PREDICTION_GRADIENT = "c1-fintech-price-prediction-gradient";
export const PRICE_WATCH_PUSH = "c1-fintech-price-watch-push";
export const PRICE_WATCH_PUSH_DEFAULT_OPT_IN =
  "c1-fintech-price-watch-push-default-optin";
export const PREFERRED_HOTEL_EXPERIMENT =
  "c1-marketplace-cot-preferred-hotel-merch";
export const LODGING_PROMOTIONS = "HotelPromotion";
export const HOTELS_HOMEPAGE_CROSS_SELL_EXPERIMENT =
  "c1-marketplace-homepage-hotel-cross-sell";
export const FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT =
  "c1-marketplace-flight-list-optimization-v1";
export const VCN_ENABLEMENT = "c1-marketplace-vcn-enablement";
export const VOID_WINDOW_EXPERIMENT = "c1_marketplace_void_window";
export const PREMIER_COLLECTION_EXPERIMENT =
  "c1-marketplace-luxury-hotels-collection";
export const AIR_MULTICITY_EXPERIMENT = "b2b-agency-air-multicity";
export const AIR_CHATBOT_EXPERIMENT = "b2b-agency-air-pricing-chatbot";
export const CREDIT_OFFER_STACKING_V1 =
  "c1-marketplace-credit-offer-stacking-v1";
export const TRAVEL_CREDIT_HISTORY_EXPERIMENT =
  "c1-marketplace-credit-transaction-history";
export const RECENTLY_VIEWED_V2_FLIGHTS =
  "c1-marketplace-recently-viewed-v2-air";
export const ANNUAL_TRAVEL_CREDITS = "c1-marketplace-annual-travel-credits";
export const RAPID_REBOOK_RENAME = "c1-disruption-rr-name-change";
export const SEATS_UX_OPTIMIZATION = "c1-marketplace-seats-ux-optimization";
export const AIR_FARECLASS_FILTER_EXPERIMENT =
  "c1-marketplace-air-fareclass-filter";
export const CAP_ONE_DISRUPTION_OPT_IN = "Cap1DisruptionPushNotifOptin";
export const NON_FDA_CAP_ONE_DISRUPTION_OPT_IN =
  "NonFDACap1DisruptionPushNotifOptin";
export const FARE_DETAILS_MOBILE = "c1-marketplace-fare-details-mobile";
export const PASSPORT_ENHANCEMENT = "c1-marketplace-passport-enhancement";
export const TRAVEL_SALE = "c1-marketplace-travel-sale";
export const CFAR_ANCILLARY_COMERCH = "c1-fintech-cfar-model-v2";
export const AIR_CX_V3_1 = "c1-marketplace-air-cx-v3.1";
export const AIR_REFUNDABLE_FARE_COPY = "C1AirRefundableFareCopy";
export const CUSTOMER_PROFILE_EXPERIMENT = "c1-marketplace-customer-profile";
export const CFAR_REMOVE_FRONTEND_ELIGIBILITY_CHECK =
  "c1-fintech-cfar-remove-frontend-eligibility-check";
export const CFAR_OFFER_SHOW_REFUND_COPY_EXPERIMENT =
  "c1-cfar-air-refund-amount";
export const CFAR_REFUND_COPY_CONTROL = "Control";
export const CFAR_REFUND_COPY_COMPARISON_CONTROL = "ComparisonControl";
export const CFAR_REFUND_COPY_Challenger1 = "RefundAmountCopy";
export const CFAR_REFUND_COPY_Challenger2 = "RefundAmountCopyAndCTA";

export const CFAR_REFUND_COPY_VARIANTS = [
  CFAR_REFUND_COPY_CONTROL,
  CFAR_REFUND_COPY_COMPARISON_CONTROL,
  CFAR_REFUND_COPY_Challenger1,
  CFAR_REFUND_COPY_Challenger2,
] as const;

export type CfarRefundCopyVariantType =
  | typeof CFAR_REFUND_COPY_CONTROL
  | typeof CFAR_REFUND_COPY_COMPARISON_CONTROL
  | typeof CFAR_REFUND_COPY_Challenger1
  | typeof CFAR_REFUND_COPY_Challenger2;

export const GLOBAL_MOBILE_NAV_EXPERIMENT = "c1-marketplace-global-mobile-nav";
export const CFAR_ENABLE_F9_NK_AIRLINE = "c1-fintech-cfar-enable-f9-nk-airline";
export const FINTECH_CSAT = "c1-fintech-csat";
export const HOTEL_CROSS_SELL_V3_EXPERIMENT =
  "c1-marketplace-hotel-cross-sell-v3";
export const HOTELS_TAXES_AND_FEES_EXPERIMENT =
  "c1-marketplace-HotelsCaliforniaBill537";
export const AIR_CX_V4 = "c1-marketplace-air-cx-v4";

// VI TEAM experiments
export const THEBES_HACKER_FARES_IN_CAP1 = "ThebesHackerFaresInCap1";
export const THEBES_VIRTUAL_INTERLINING_IN_CAP1 =
  "ThebesVirtualInterliningInCap1";

export const CFAR_DISCOUNT = "c1-cfar-air-discount";

// variants for c1-cfar-air-discount
export const CFAR_DISCOUNT_CONTROL = "control";
export const CFAR_DISCOUNT_10_PERCENT = "10percentdiscount";
export const CFAR_DISCOUNT_25_PERCENT = "25percentdiscount";
export const CFAR_DISCOUNT_75_PERCENT = "75percentdiscount";
export const CFAR_DISCOUNT_100_PERCENT = "100percentdiscount";
export const CFAR_DISCOUNT_VARIANTS = [
  CFAR_DISCOUNT_CONTROL,
  CFAR_DISCOUNT_10_PERCENT,
  CFAR_DISCOUNT_25_PERCENT,
  CFAR_DISCOUNT_75_PERCENT,
  CFAR_DISCOUNT_100_PERCENT,
] as const;
export type CfarDiscountVariantType =
  | typeof CFAR_DISCOUNT_CONTROL
  | typeof CFAR_DISCOUNT_10_PERCENT
  | typeof CFAR_DISCOUNT_25_PERCENT
  | typeof CFAR_DISCOUNT_75_PERCENT
  | typeof CFAR_DISCOUNT_100_PERCENT;

export const VI_EXPERIMENT_CONTROL = "Control";
export const VI_EXPERIMENT_AVAILABLE = "Available";
export const VI_EXPERIMENT_VARIANTS = [
  VI_EXPERIMENT_CONTROL,
  VI_EXPERIMENT_AVAILABLE,
] as const;
export type VIVariantType =
  | typeof VI_EXPERIMENT_CONTROL
  | typeof VI_EXPERIMENT_AVAILABLE;

// variants for c1-agency-virtual-interlining-shop-banners
export const VI_SHOP_BANNERS_EXPERIMENT =
  "c1-agency-virtual-interlining-shop-banners";
export const VI_SHOP_BANNERS_CONTROL = "control";
export const VI_SHOP_BANNERS_NO_BANNER = "no-banner";
export const VI_SHOP_BANNERS_GRAY_BANNER = "gray-banner";
export const VI_SHOP_BANNERS_VARIANTS = [
  VI_SHOP_BANNERS_CONTROL,
  VI_SHOP_BANNERS_NO_BANNER,
  VI_SHOP_BANNERS_GRAY_BANNER,
] as const;

// Default variants
export const CONTROL = "control";
export const AVAILABLE = "available";
export const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
export type DefaultVariantType = typeof CONTROL | typeof AVAILABLE;

// Uppercase Default variants
export const UC_CONTROL = "Control";
export const UC_AVAILABLE = "Available";
export const UC_DEFAULT_VARIANTS = [UC_CONTROL, UC_AVAILABLE] as const;
export type UppercaseDefaultVariantType =
  | typeof UC_CONTROL
  | typeof UC_AVAILABLE;

// Variants for CAP_ONE_DISRUPTION_OPT_IN
export const CAP_ONE_DISRUPTION_OPT_IN_CONTROL = "control";
export const CAP_ONE_DISRUPTION_OPT_IN_COMPARISON_CONTROL =
  "comparison-control";
export const CAP_ONE_DISRUPTION_OPT_IN_SCREEN_WITH_PUSH_OPT_IN =
  "screen-with-push-opt-in";
export const CAP_ONE_DISRUPTION_OPT_IN_3_HOUR_THRESHOLD =
  "opt-in-3-hr-threshold";
export const CAP_ONE_DISRUPTION_OPT_IN_VARIANTS = [
  CAP_ONE_DISRUPTION_OPT_IN_CONTROL,
  CAP_ONE_DISRUPTION_OPT_IN_COMPARISON_CONTROL,
  CAP_ONE_DISRUPTION_OPT_IN_SCREEN_WITH_PUSH_OPT_IN,
  CAP_ONE_DISRUPTION_OPT_IN_3_HOUR_THRESHOLD,
] as const;

export type CapOneDisruptionOptInVariantType =
  | typeof CAP_ONE_DISRUPTION_OPT_IN_CONTROL
  | typeof CAP_ONE_DISRUPTION_OPT_IN_COMPARISON_CONTROL
  | typeof CAP_ONE_DISRUPTION_OPT_IN_SCREEN_WITH_PUSH_OPT_IN
  | typeof CAP_ONE_DISRUPTION_OPT_IN_3_HOUR_THRESHOLD;

// Variants for ANCILLARY_MARKETPLACE
export const ANCILLARY_MARKETPLACE_CONTROL = "control";
export const ANCILLARY_MARKETPLACE_SINGLE_PAGE = "single-page";
export const ANCILLARY_MARKETPLACE_MULTI_PAGE = "multi-page";
export const ANCILLARY_MARKETPLACE_FLIGHT_BOOK = "flight-book";
export const ANCILLARY_MARKETPLACE_VARIANTS = [
  ANCILLARY_MARKETPLACE_CONTROL,
  ANCILLARY_MARKETPLACE_SINGLE_PAGE,
  ANCILLARY_MARKETPLACE_MULTI_PAGE,
  ANCILLARY_MARKETPLACE_FLIGHT_BOOK,
] as const;
export type AncillaryMarketplaceVariantType =
  | typeof ANCILLARY_MARKETPLACE_CONTROL
  | typeof ANCILLARY_MARKETPLACE_SINGLE_PAGE
  | typeof ANCILLARY_MARKETPLACE_MULTI_PAGE
  | typeof ANCILLARY_MARKETPLACE_FLIGHT_BOOK;

// Variants for CFAR_SOCIAL
// Note that we are using this experiment for social proofing in general now, rather than just CFAR.
export const CFAR_SOCIAL_CONTROL = "control";
export const CFAR_SOCIAL_SOCIAL_PROOF = "social-proof";
export const CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY = "social-proof-testimony";
export const CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY_CFAR_FDA =
  "social-proof-testimony-CFAR-FDA";
export const CFAR_SOCIAL_VARIANTS = [
  CFAR_SOCIAL_CONTROL,
  CFAR_SOCIAL_SOCIAL_PROOF,
  CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY,
  CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY_CFAR_FDA,
] as const;
export type CfarSocialVariantType =
  | typeof CFAR_SOCIAL_CONTROL
  | typeof CFAR_SOCIAL_SOCIAL_PROOF
  | typeof CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY
  | typeof CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY_CFAR_FDA;

export type PriceFreezePositioningVariantType = DefaultVariantType;

export const PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS =
  "c1-fintech-pf-user-selected-duration-new-caps";
export const PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS_CONTROL = "control";
export const PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS_1000_CAP = "1000-cap";
export const PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS_500_CAP = "500-cap";
export const PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS_VARIANTS = [
  PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS_CONTROL,
  PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS_1000_CAP,
  PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS_500_CAP,
] as const;
export type PriceFreezeUserSelectedDurationNewCapsType =
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS_CONTROL
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS_1000_CAP
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_NEW_CAPS_500_CAP;

// Variants for PRICE_FREEZE_VOID_WINDOW
export const PRICE_FREEZE_VOID_WINDOW_CONTROL = "control";
export const PRICE_FREEZE_VOID_WINDOW_CONTEXTUAL_DISCLOSURE =
  "contextual-disclosure";
export const PRICE_FREEZE_VOID_WINDOW_NAV_DISCLOSURE = "nav-disclosure";
export const PRICE_FREEZE_VOID_WINDOW_VARIANTS = [
  PRICE_FREEZE_VOID_WINDOW_CONTROL,
  PRICE_FREEZE_VOID_WINDOW_CONTEXTUAL_DISCLOSURE,
  PRICE_FREEZE_VOID_WINDOW_NAV_DISCLOSURE,
] as const;
export type PriceFreezeVoidWindowType =
  | typeof PRICE_FREEZE_VOID_WINDOW_CONTROL
  | typeof PRICE_FREEZE_VOID_WINDOW_CONTEXTUAL_DISCLOSURE
  | typeof PRICE_FREEZE_VOID_WINDOW_NAV_DISCLOSURE;

// Variants for c1-air-price-freeze-default-durations
export const PRICE_FREEZE_DEFAULT_DURATIONS_CONTROL = "Control";
export const PRICE_FREEZE_DEFAULT_DURATIONS_MIDDLE = "Middle";
export const PRICE_FREEZE_DEFAULT_DURATIONS_LAST = "Last";
export const PRICE_FREEZE_DEFAULT_DURATIONS_KNOCKOUT = "KnockOut";
export const PRICE_FREEZE_DEFAULT_DURATIONS_VARIANTS = [
  PRICE_FREEZE_DEFAULT_DURATIONS_CONTROL,
  PRICE_FREEZE_DEFAULT_DURATIONS_MIDDLE,
  PRICE_FREEZE_DEFAULT_DURATIONS_LAST,
  PRICE_FREEZE_DEFAULT_DURATIONS_KNOCKOUT,
] as const;
export type PriceFreezeDefaultDurationsType =
  | typeof PRICE_FREEZE_DEFAULT_DURATIONS_CONTROL
  | typeof PRICE_FREEZE_DEFAULT_DURATIONS_MIDDLE
  | typeof PRICE_FREEZE_DEFAULT_DURATIONS_LAST
  | typeof PRICE_FREEZE_DEFAULT_DURATIONS_KNOCKOUT;

// Variants for AirPriceFreezeOnlyFreezeQuotedPrice
export const AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE_CONTROL = "Control";
export const AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE_AVAILABLE = "Available";
export const AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE_VARIANTS = [
  AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE_CONTROL,
  AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE_AVAILABLE,
] as const;
export type AirPriceFreezeOnlyFreezeQuotedPriceVariantType =
  | typeof AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE_CONTROL
  | typeof AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE_AVAILABLE;

// Variants for PRICE_FREEZE_SHOW_DURATIONS
export const PRICE_FREEZE_SHOW_DURATIONS_CONTROL = "Control";
export const PRICE_FREEZE_SHOW_DURATIONS_VARIANT1 = "Variant1";
export const PRICE_FREEZE_SHOW_DURATIONS_VARIANTS = [
  PRICE_FREEZE_SHOW_DURATIONS_CONTROL,
  PRICE_FREEZE_SHOW_DURATIONS_VARIANT1,
] as const;
export type PriceFreezeShowDurationsType =
  | typeof PRICE_FREEZE_SHOW_DURATIONS_CONTROL
  | typeof PRICE_FREEZE_SHOW_DURATIONS_VARIANT1;

// Variants for PRICE_FREEZE_USER_SELECTED_DURATION
export const PRICE_FREEZE_USER_SELECTED_DURATION_CONTROL = "control";
export const PRICE_FREEZE_USER_SELECTED_DURATION_USER_SELECTED =
  "user-selected";
export const PRICE_FREEZE_USER_SELECTED_DURATION_VARIANTS = [
  PRICE_FREEZE_USER_SELECTED_DURATION_CONTROL,
  PRICE_FREEZE_USER_SELECTED_DURATION_USER_SELECTED,
] as const;
export type PriceFreezeUserSelectedDurationVariantType =
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_CONTROL
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_USER_SELECTED;

// Variants for PRICE_FREEZE_USER_SELECTED_DURATION_COPY
export const PRICE_FREEZE_USER_SELECTED_DURATION_COPY_CONTROL = "control";
export const PRICE_FREEZE_USER_SELECTED_DURATION_COPY_AVAILABLE = "available";
export const PRICE_FREEZE_USER_SELECTED_DURATION_COPY_DEFAULT_12_HR =
  "default-12-hr";
export const PRICE_FREEZE_USER_SELECTED_DURATION_COPY_VARIANTS = [
  PRICE_FREEZE_USER_SELECTED_DURATION_COPY_CONTROL,
  PRICE_FREEZE_USER_SELECTED_DURATION_COPY_AVAILABLE,
  PRICE_FREEZE_USER_SELECTED_DURATION_COPY_DEFAULT_12_HR,
] as const;
export type PriceFreezeUserSelectedDurationCopyVariantType =
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_COPY_CONTROL
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_COPY_AVAILABLE
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_COPY_DEFAULT_12_HR;

// Variants for PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY
export const PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_CONTROL = "control";
export const PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_POPUP = "pop-up";
export const PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_VARIANTS = [
  PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_CONTROL,
  PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_POPUP,
] as const;
export type PriceFreezeUserSelectedDurationDisplayVariantType =
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_CONTROL
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_POPUP;

// Variants for PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER
export const PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_CONTROL = "control";
export const PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_BUTTONS = "buttons";
export const PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_VARIANTS = [
  PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_CONTROL,
  PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_BUTTONS,
] as const;
export type PriceFreezeUserSelectedDurationNoSliderVariantType =
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_CONTROL
  | typeof PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_BUTTONS;

// Variants for PRICE_PREDICTION_GRADIENT
export const PRICE_PREDICTION_GRADIENT_CONTROL = "control";
export const PRICE_PREDICTION_GRADIENT_V2 = "v2";
export const PRICE_PREDICTION_GRADIENT_VARIANTS = [
  PRICE_PREDICTION_GRADIENT_CONTROL,
  PRICE_PREDICTION_GRADIENT_V2,
] as const;
export type PricePredictionGradientVariantType =
  | typeof PRICE_PREDICTION_GRADIENT_CONTROL
  | typeof PRICE_PREDICTION_GRADIENT_V2;

// Variants for c1-marketplace-cash-valueprop-merch
export const CASH_VALUEPROP_A = "cash-valueprop-a";
export const CASH_VALUEPROP_B = "cash-valueprop-b";
export const CASH_VALUEPROP_C = "cash-valueprop-c";
export const CASH_VALUEPROP_VARIANTS = [
  CONTROL,
  CASH_VALUEPROP_A,
  CASH_VALUEPROP_B,
  CASH_VALUEPROP_C,
] as const;
export type CashValuePropVariantType =
  | typeof CONTROL
  | typeof CASH_VALUEPROP_A
  | typeof CASH_VALUEPROP_B
  | typeof CASH_VALUEPROP_C;

// Variants for c1-marketplace-mobile-homescreen-redesign
export const MOBILE_HOMESCREEN_REDESIGN_V1 = "variant-1";
export const MOBILE_HOMESCREEN_REDESIGN_V2 = "variant-2";
export const MOBILE_HOMESCREEN_REDESIGN_V3 = "variant-3";
export const MOBILE_HOMESCREEN_REDESIGN_VARIANTS = [
  CONTROL,
  MOBILE_HOMESCREEN_REDESIGN_V1,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_V3,
] as const;

export type MobileHomescreenRedesignPropVariantType =
  | typeof CONTROL
  | typeof MOBILE_HOMESCREEN_REDESIGN_V1
  | typeof MOBILE_HOMESCREEN_REDESIGN_V2
  | typeof MOBILE_HOMESCREEN_REDESIGN_V3;

// Variants for c1-marketplace-cot-preferred-hotel-merch
export const PREFERRED_HOTELS_V1 = "v1-special-rates";
export const PREFERRED_HOTELS_VARIANT = [CONTROL, PREFERRED_HOTELS_V1] as const;
export type PreferredHotelsPropVariantType =
  | typeof CONTROL
  | typeof PREFERRED_HOTELS_V1;

// Variants for HotelPromotion
export const LODGING_PROMOTIONS_AVAILABLE = "Available";
export const LODGING_PROMOTIONS_VARIANTS = [
  CONTROL,
  LODGING_PROMOTIONS_AVAILABLE,
] as const;
export type LodgingPromotionsPropVariantType =
  | typeof CONTROL
  | typeof LODGING_PROMOTIONS_AVAILABLE;

export const AIR_OFFER_REDESIGN = "c1-fintech-disruption-air-offerredesign";
// variants for c1-fintech-disruption-air-offerredesign
export const AIR_OFFER_REDESIGN_CONTROL = "control";
export const AIR_OFFER_REDESIGN_COMPARISON_CONTROL = "comparison-control";
export const AIR_OFFER_REDESIGN_TABLE_CONCEPT = "table-concept";
export const AIR_OFFER_REDESIGN_VARIANTS = [
  AIR_OFFER_REDESIGN_CONTROL,
  AIR_OFFER_REDESIGN_COMPARISON_CONTROL,
  AIR_OFFER_REDESIGN_TABLE_CONCEPT,
] as const;
export type AirOfferRedesignVariantType =
  | typeof AIR_OFFER_REDESIGN_CONTROL
  | typeof AIR_OFFER_REDESIGN_COMPARISON_CONTROL
  | typeof AIR_OFFER_REDESIGN_TABLE_CONCEPT;

// Variants for c1-marketplace-travel-sale
export const TRAVEL_SALE_LEAD_UP = "lead-up";
export const TRAVEL_SALE_ACTIVE = "active";
export const TRAVEL_SALE_VARIANTS = [
  CONTROL,
  TRAVEL_SALE_LEAD_UP,
  TRAVEL_SALE_ACTIVE,
] as const;
export type TravelSalePropVariantType =
  | typeof CONTROL
  | typeof TRAVEL_SALE_LEAD_UP
  | typeof TRAVEL_SALE_ACTIVE;

// variants for CFAR CoMerch
export const CFAR_COMERCH_CONTROL = "Control";
export const CFAR_COMERCH_90_70_SHELF_ADV_GBV = "comerch90and70shelfadvgbv";
export const CFAR_COMERCH_90_70_RANDOM = "comerch90and70random";
export const CFAR_COMERCH_90_80_SHELF_ADV_GBV = "comerch90and80shelfadvgbv";
export const CFAR_COMERCH_90_80_RANDOM = "comerch90and80random";
export const CFAR_COMERCH_VARIANTS = [
  CFAR_COMERCH_CONTROL,
  CFAR_COMERCH_90_70_SHELF_ADV_GBV,
  CFAR_COMERCH_90_70_RANDOM,
  CFAR_COMERCH_90_80_SHELF_ADV_GBV,
  CFAR_COMERCH_90_80_RANDOM,
] as const;
export type CFARCoMerchVariantType =
  | typeof CFAR_COMERCH_CONTROL
  | typeof CFAR_COMERCH_90_70_SHELF_ADV_GBV
  | typeof CFAR_COMERCH_90_70_RANDOM
  | typeof CFAR_COMERCH_90_80_SHELF_ADV_GBV
  | typeof CFAR_COMERCH_90_80_RANDOM;

// Variants for c1-fintech-disruption-air-postbookingoffer
export const POST_BOOKING_OFFER_3 = "available-3";
export const POST_BOOKING_OFFER_7 = "available-7";
export const POST_BOOKING_OFFER_14 = "available-14";
export const POST_BOOKING_OFFER_VARIANTS = [
  POST_BOOKING_OFFER_3,
  POST_BOOKING_OFFER_7,
  POST_BOOKING_OFFER_14,
  CONTROL,
  AVAILABLE,
] as const;

export type PostBookingOfferVariantType =
  | typeof CONTROL
  | typeof AVAILABLE
  | typeof POST_BOOKING_OFFER_3
  | typeof POST_BOOKING_OFFER_7
  | typeof POST_BOOKING_OFFER_14;

// Variants for c1-fintech-disruption-air-postbookingoffer-rewards
export const POST_BOOKING_OFFER_REWARDS_MERCH = "available-with-merch";
export const POST_BOOKING_OFFER_REWARDS_VARIANTS = [
  POST_BOOKING_OFFER_REWARDS_MERCH,
  CONTROL,
  AVAILABLE,
] as const;

export type PostBookingOfferRewardsVariantType =
  | typeof CONTROL
  | typeof AVAILABLE
  | typeof POST_BOOKING_OFFER_REWARDS_MERCH;

// Variants for c1-fintech-disruption-air-summer-2024-copy
export const DISRUPTION_AIR_SUMMER_COPY_VARIANTS = [
  CONTROL,
  AVAILABLE,
] as const;

export type DisruptionAirSummerCopyVariantType =
  | typeof CONTROL
  | typeof AVAILABLE;

// Variants c1-marketplace-air-cx-v3.1
export const AIR_CX_V3_1_VARIANT_1 = "variant-1";
export const AIR_CX_V3_1_VARIANT_2 = "variant-2";
export const AIR_CX_V3_1_VARIANTS = [
  CONTROL,
  AIR_CX_V3_1_VARIANT_1,
  AIR_CX_V3_1_VARIANT_2,
] as const;
export type AirCXV3VariantType =
  | typeof CONTROL
  | typeof AIR_CX_V3_1_VARIANT_1
  | typeof AIR_CX_V3_1_VARIANT_2;

// Variants for c1-marketplace-customer-profile
export const DEFAULT_OFF = "default-off";
export const DEFAULT_ON = "default-on";
export const CUSTOMER_PROFILE_VARIANTS = [
  CONTROL,
  DEFAULT_OFF,
  DEFAULT_ON,
] as const;
export type CustomerProfileVariantType =
  | typeof CONTROL
  | typeof DEFAULT_OFF
  | typeof DEFAULT_ON;

// Variants c1-marketplace-hotel-cross-sell-v3
export const HOTEL_CROSS_SELL_V3_VARIANT_1 = "dollar-offer";
export const HOTEL_CROSS_SELL_V3_VARIANT_2 = "percentage-offer";
export const HOTEL_CROSS_SELL_V3_VARIANTS = [
  CONTROL,
  HOTEL_CROSS_SELL_V3_VARIANT_1,
  HOTEL_CROSS_SELL_V3_VARIANT_2,
] as const;
export type HotelCrossSellV3VariantType =
  | typeof CONTROL
  | typeof HOTEL_CROSS_SELL_V3_VARIANT_1
  | typeof HOTEL_CROSS_SELL_V3_VARIANT_2;
// Corporate Travel
export const CORP_HIDE_PRICE_DROP_EXPERIMENT =
  "corp-hide-price-drop-protection";

// Variants for c1-marketplace-HotelsCaliforniaBill537
export const LEAST_DISRUPTION = "least-disruption";
export const BEST_COMPLIANCE = "best-compliance";
export const HOTELS_TAXES_AND_FEES_VARIANTS = [
  CONTROL,
  LEAST_DISRUPTION,
  BEST_COMPLIANCE,
] as const;
export type HotelsTaxesAndFeesVariantType =
  | typeof CONTROL
  | typeof LEAST_DISRUPTION
  | typeof BEST_COMPLIANCE;

const defaultInitializer = (): ExperimentState => {
  return {
    experiments: [],
    trackingProperties: undefined,
  };
};

export const ExperimentsContext = createContext<ExperimentState | undefined>(
  undefined
);

// readonly (together with const restriction below) ensures typeof supportedVariants[number]
// can be recognized by transpiler as an array of constants
// [string, ...string[]] ensures array is not empty.
export function getExperimentVariantCustomVariants<
  T extends readonly [string, ...string[]]
>(
  experiments: Array<Experiment>,
  experimentId: string,
  // List of supported variants. If the variant retreived is not recognized by the code, the code will fall back to the first variant.
  // Note the input list needs to be declared as const e.g. const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
  supportedVariants: T
): (typeof supportedVariants)[number] {
  const experiment = experiments?.find((exp) => exp.id === experimentId);
  if (experiment) {
    if (supportedVariants.includes(experiment.variant)) {
      return experiment.variant;
    } else {
      return supportedVariants[0];
    }
  } else {
    return supportedVariants[0];
  }
}

export function getExperimentVariant(
  experiments: Array<Experiment>,
  experimentId: string
): DefaultVariantType {
  return getExperimentVariantCustomVariants(
    experiments,
    experimentId,
    DEFAULT_VARIANTS
  );
}

export function useExperiments(): ExperimentState {
  const ctx = useContext(ExperimentsContext);
  if (!ctx) throw new Error(`must be used within a ExperimentsProvider`);
  return ctx;
}

export function useExperiment(
  experimentId: string,
  target: string = ExperimentVariant.AVAILABLE
) {
  const { experiments } = useExperiments();
  if (!experiments) throw new Error("No experiments found in Context");
  const expVariant = getExperimentVariant(experiments, experimentId);

  return expVariant === target;
}

export function addTrackingProperties(
  trackingProperties: Map<string, string> | undefined,
  properties?: any
): unknown {
  if (!properties) {
    properties = {};
  }
  if (trackingProperties) {
    properties["experiments"] = Object.keys(trackingProperties).map(
      (ex) => `${ex}_${trackingProperties![ex]}`
    );
  }
  return properties;
}

const ExperimentsProvider: FC<
  { initState?: ExperimentState } & PropsWithChildren
> = ({ initState = defaultInitializer(), children }) => {
  // TODO: use tracking reducers
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!initState?.experiments.length) {
      const fetchExperiments = async () => {
        await fetchActiveExperiments().then((result) => {
          setState(result as ExperimentState);
        });
      };
      fetchExperiments();
    }
  }, []);

  return (
    <ExperimentsContext.Provider value={state}>
      {children}
    </ExperimentsContext.Provider>
  );
};

export default ExperimentsProvider;
